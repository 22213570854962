







































































// import { StakingViewModel } from '../viewmodels/staking-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import AddWinnerViewModel from '../viewmodels/add-winner-viewmodel'

@Observer
@Component({
  components: {}
})
export default class Claimer extends Vue {
  @Inject() vm!: AddWinnerViewModel
  walletStore = walletStore

  mounted() {
    this.vm.handleSending()
  }
  copyHash(item) {
    navigator.clipboard.writeText(item.transactionInfo)
  }
}
